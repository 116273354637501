import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    inject
} from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
    ENVIRONMENT,
    IEnvironment,
    IsJustPipe,
    objectHasValue,
    WithDefaultPipe
} from '@trade-platform/ui-utils';
import { Subject, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { just, nothing } from 'ts.data.maybe';
import { DynamicFormStore } from '../../dynamic-form-store';
import {
    DynamicFormCheckboxGroupRecalculateDisabledOptionsAction,
    DynamicFormControlSetDirtyAction,
    DynamicFormControlValidateAction,
    DynamicFormSetDataAction
} from '../../dynamic-form-store/actions';
import { CheckboxGroupControlState, DynamicFormState, RefId } from '../../dynamic-form-store/model';
import { getDataPathByFieldConfig } from '../../dynamic-form-store/utils';
import { getHostPropertyClass } from '../../dynamic-form.constants';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { Checkboxgroup2FieldConfig, OptionFieldConfig } from '@trade-platform/form-fields';
import { Field, FieldEvent } from '../field.interface';
import { REQUIRED_CHECKS_VALIDATOR_KEY } from './required-checks-validator';
import { isMatch } from 'lodash-es';
import { NgClass } from '@angular/common';
import { AixDataTestingDirective, AixTooltipDirective } from '@trade-platform/ui-components';
import { AixDynamicNextPendingFieldDirective } from '../../directives/dynamic-next-pending-field';

@Component({
    selector: 'aix-dynamic-checkboxgroup2',
    styleUrls: ['./checkboxgroup.component.scss'],
    templateUrl: './checkboxgroup.component2.html',
    standalone: true,
    imports: [
        AixDataTestingDirective,
        AixTooltipDirective,
        AixDynamicNextPendingFieldDirective,
        NgClass,
        WithDefaultPipe,
        IsJustPipe
    ]
})
export class AixDynamicCheckboxgroup2Component implements Field, OnInit, OnDestroy {
    helper = inject(DynamicFormHelper);
    private store = inject<Store<Record<string, DynamicFormState>>>(Store);
    private formStore = inject(DynamicFormStore);
    private elemRef = inject(ElementRef);
    private cd = inject(ChangeDetectorRef);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    // Static
    static HOST_CLASS = 'aix-flex-grid aix-form__grid flex-col aix-blink';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicCheckboxgroup2Component.HOST_CLASS;

    @HostBinding('attr.data-testing')
    dataTesting: string;

    @HostBinding('attr.aix-control')
    aixControl: string;

    // Store Data
    templateData = {
        options: [] as OptionFieldConfig[],
        optionsData: {} as Record<RefId, boolean>,
        fieldIsDisabled: false,
        fieldIsRequired: false,
        fieldIsDirty: false,
        ctrlHasUncompleteError: false,
        ctrlHasOverError: false,
        ctrlHasRequiredChecksError: false,
        validationError: nothing<string>()
    };
    userInputEmitter$ = new Subject<{ option: OptionFieldConfig; checkbox: HTMLInputElement }>();

    // Other
    config: Checkboxgroup2FieldConfig;
    private subscriptions: Subscription[] = [];
    ceil = Math.ceil;
    bodyProperties: string[];

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.cd.detach();
    }

    onCheckChange(event: Event, option: OptionFieldConfig) {
        const checkbox = event.target as HTMLInputElement;
        this.userInputEmitter$.next({ option, checkbox });
    }

    ngOnInit() {
        const formUID = this.formStore.formUID;
        const refId = this.config.refId as string;
        this.aixControl = refId;
        this.classNames = this.calculateClassNames();
        this.bodyProperties = this.helper.parseBodyProperties(this.config.classNames?.body);

        // Currently binding a directive to the host is not possible in Angular (but on their roadmap - https://angular.io/guide/roadmap#support-adding-directives-to-host-elements);
        // Conditionally set this value based on the active environment here instead;
        if (this.environment.environment !== 'production') {
            this.dataTesting = refId;
        }

        // Control initialization
        this.formStore.addCheckboxgroup2(this.config);

        const ctrlStore$ = this.formStore.getControlStoreByRefId<CheckboxGroupControlState>(refId);
        const optionsData$ = this.formStore.getDataStore<Record<RefId, boolean>>(
            getDataPathByFieldConfig(this.config)
        );

        this.subscriptions.push(
            ctrlStore$.pipe(withLatestFrom(optionsData$)).subscribe(([ctrl, optionsData]) => {
                // options
                this.templateData.options = ctrl.extra.options;

                // Selected option has been filtered, so we have to clear the data
                if (optionsData) {
                    Object.keys(optionsData).forEach(opt => {
                        const itemExist = ctrl.extra.options.some(item => item.refId === opt);
                        if (!itemExist) {
                            delete optionsData[opt];
                            this.store.dispatch(
                                new DynamicFormControlValidateAction(formUID, [
                                    this.config.refId as string
                                ])
                            );
                        }
                    });

                    if (!isMatch(optionsData, this.templateData.optionsData)) {
                        this.store.dispatch(
                            new DynamicFormSetDataAction(
                                formUID,
                                getDataPathByFieldConfig(this.config),
                                optionsData
                            )
                        );
                    }
                }

                // optionsData
                this.templateData.optionsData = optionsData;

                // fieldIsDisabled
                this.templateData.fieldIsDisabled =
                    ctrl.disabledByRelation || ctrl.fieldConfig.disabled === true;

                // fieldIsRequired
                const hasRequiredValidator = (ctrl.fieldConfig.validation as ValidatorFn[]).some(
                    val => val === Validators.required
                );
                this.templateData.fieldIsRequired = ctrl.requiredByRelation || hasRequiredValidator;

                // fieldIsDirty
                this.templateData.fieldIsDirty = ctrl.isDirty;

                this.templateData.validationError = nothing();

                const ctrlErrors = ctrl.validation;

                // ctrlHasUncompleteError
                this.templateData.ctrlHasUncompleteError =
                    ctrlErrors !== null && ctrlErrors['uncomplete'];

                // ctrlHasOverError
                this.templateData.ctrlHasOverError = ctrlErrors !== null && ctrlErrors['over'];
                if (this.templateData.ctrlHasOverError) {
                    this.templateData.validationError = just(
                        `You have selected more than ${this.config.limit} options, please choose only ${this.config.limit}.`
                    );
                }

                // ctrlHasRequiredChecksError
                this.templateData.ctrlHasRequiredChecksError =
                    ctrlErrors !== null && ctrlErrors[REQUIRED_CHECKS_VALIDATOR_KEY];
                if (this.templateData.ctrlHasRequiredChecksError) {
                    this.templateData.validationError = just(
                        ctrlErrors?.[REQUIRED_CHECKS_VALIDATOR_KEY]
                    );
                }

                // TODO: Server Validation errors not implemented. To be decided if it makes sense here.

                this.cd.detectChanges();
            })
        );

        // User Input
        this.subscriptions.push(
            this.userInputEmitter$.subscribe(({ option, checkbox }) => {
                const newData = Object.assign(this.templateData.optionsData, {
                    [option.refId as string]: checkbox.checked
                });

                // Uncheck child when parent is unchecked
                const hasChild = Object.keys(newData).some(
                    key =>
                        this.config.anyOf.filter((item: any) => item.parentRefId === option.refId)
                            .length
                );
                if (hasChild && !checkbox.checked) {
                    this.config.anyOf
                        .filter((item: any) => item.parentRefId === option.refId)
                        .forEach((child: any) => (newData[child.refId] = false));
                }

                // Uncheck unchecker
                if (
                    objectHasValue(this.config.onCheck) &&
                    objectHasValue(this.config.onCheck.exclusive) &&
                    this.config.onCheck.exclusive &&
                    this.config.onCheck.refId !== option.refId &&
                    newData[option.refId as string]
                ) {
                    newData[this.config.onCheck.refId] = false;
                }

                // Uncheck all
                if (
                    objectHasValue(this.config.onCheck) &&
                    this.config.onCheck.uncheckAll &&
                    this.config.onCheck.refId === option.refId &&
                    newData[option.refId]
                ) {
                    Object.keys(newData).forEach(key => {
                        newData[key] = false;
                    });
                    newData[option.refId] = true;
                }

                this.store.dispatch(
                    new DynamicFormSetDataAction(
                        formUID,
                        getDataPathByFieldConfig(this.config),
                        newData
                    )
                );

                this.store.dispatch(
                    new DynamicFormCheckboxGroupRecalculateDisabledOptionsAction(
                        formUID,
                        this.config.refId as string
                    )
                );

                this.store.dispatch(new DynamicFormControlSetDirtyAction(formUID, refId));
            })
        );

        // Notifications
        this.subscriptions.push(
            this.userInputEmitter$.subscribe(_ => {
                if (
                    (Array.isArray(this.config.value) && this.config.value.length > 0) ||
                    (typeof this.config.value === 'string' && this.config.value.length > 0)
                ) {
                    const aixEvt = new CustomEvent(FieldEvent.CHANGE, {
                        detail: this.config,
                        bubbles: true,
                        cancelable: true
                    });
                    this.elemRef.nativeElement.dispatchEvent(aixEvt);
                }
            })
        );

        this.cd.detach();
    }

    trackByFn(index: number, item: OptionFieldConfig) {
        return item.refId;
    }

    private calculateClassNames() {
        let classNames = this.config.classNames
            ? [
                  AixDynamicCheckboxgroup2Component.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicCheckboxgroup2Component.HOST_CLASS;
        classNames = this.config.hidden
            ? classNames.concat(` ${getHostPropertyClass().hidden}`)
            : classNames;
        return classNames;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.formStore.removeCheckboxgroup2(this.config);
    }
}
