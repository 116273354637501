import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    inject
} from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { auditTime, debounceTime, filter, first, map, withLatestFrom } from 'rxjs/operators';
import { getHostPropertyClass } from '../../dynamic-form.constants';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { IntlPhoneFieldConfig } from '@trade-platform/form-fields';
import { Field, FieldEvent } from '../field.interface';
import {
    createSeparatorMask,
    stringIsNotEmpty,
    unMaskSeparatorValue
} from '@trade-platform/ui-utils';
import { getDataPathByFieldConfig } from '../../dynamic-form-store/utils';
import {
    DynamicFormControlSetDirtyAction,
    DynamicFormSetDataAction
} from '../../dynamic-form-store/actions';
import { DynamicFormStore } from '../../dynamic-form-store';
import { handleServerValdationErrors } from '../../dynamic-form.utils';
import {
    AixDataTestingDirective,
    AixIntlPhoneComponent,
    IntlPhoneValue
} from '@trade-platform/ui-components';
import { AixFormErrorsPipe } from '../../../pipes/form-errors';
import { AixDynamicNextPendingFieldDirective } from '../../directives/dynamic-next-pending-field';

@Component({
    selector: 'aix-dynamic-intl-phone',
    styleUrls: ['./intl-phone.component.scss'],
    templateUrl: './intl-phone.component.html',
    standalone: true,
    imports: [
        AixIntlPhoneComponent,
        AixDynamicNextPendingFieldDirective,
        AixDataTestingDirective,
        AixFormErrorsPipe
    ]
})
export class AixDynamicIntlPhoneComponent implements Field, OnInit, OnDestroy {
    private helper = inject(DynamicFormHelper);
    private store = inject<Store<any>>(Store);
    private formStore = inject(DynamicFormStore);
    private elemRef = inject(ElementRef);
    private cd = inject(ChangeDetectorRef);

    static HOST_CLASS = 'aix-flex-grid__col';

    @HostBinding('class')
    classNames = AixDynamicIntlPhoneComponent.HOST_CLASS;

    @HostBinding('attr.aix-control')
    aixControl: string;

    // Store Data
    templateData = {
        fieldIsDisabled: false,
        fieldIsRequired: false,
        fieldIsDirty: false,
        ctrlIsInvalid: false,
        ctrlHasRequiredError: false,
        canDisplayErrors: false,
        ctrlErrors: null as ValidationErrors | null
    };
    userInputEmitter$ = new Subject<IntlPhoneValue>();
    debouncer = new Subject<IntlPhoneValue>();

    // Other
    config: IntlPhoneFieldConfig;
    configCountryCode: IntlPhoneFieldConfig;
    private subscriptions: Subscription[] = [];

    value: IntlPhoneValue;

    private readonly MAX_LENGTH = 10;
    private mask = createSeparatorMask([3, 6], this.MAX_LENGTH, '-');
    private isTyping = false;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.cd.detach();

        const formUID = this.formStore.formUID;

        // User input
        this.subscriptions.push(
            this.userInputEmitter$.subscribe(value => {
                this.store.dispatch(
                    new DynamicFormSetDataAction(
                        formUID,
                        getDataPathByFieldConfig(this.config),
                        (value as unknown as IntlPhoneValue).phoneNumber
                    )
                );

                this.store.dispatch(
                    new DynamicFormSetDataAction(
                        formUID,
                        getDataPathByFieldConfig({
                            ...this.config,
                            name: this.config.countryCodeName
                        }),
                        (value as unknown as IntlPhoneValue).countryCode
                    )
                );
            }),
            this.debouncer
                .pipe(debounceTime(this.helper.INPUT_DEBOUNCE))
                .subscribe(value => this.userInputEmitter$.next(value))
        );
    }

    ngOnInit() {
        this.classNames = this.calculateClassNames();

        const refId = this.config.refId as string;

        // Control initialization
        this.aixControl = refId;
        this.formStore.addControl(this.config);

        this.configCountryCode = {
            ...this.config,
            refId: `${this.config.refId}CountryCode`,
            name: this.config.countryCodeName
        };
        this.formStore.addControl(this.configCountryCode);

        // Store Control Observables
        const ctrlStore$ = this.formStore.getControlStoreByRefId(refId);
        const valueToRender$ = this.formStore
            .getDataStore<string>(getDataPathByFieldConfig(this.config))
            .pipe(map(value => this.mask(unMaskSeparatorValue(value) as string)));

        this.subscriptions.push(
            ctrlStore$
                .pipe(auditTime(5), withLatestFrom(valueToRender$))
                .subscribe(([ctrl, valueToRender]) => {
                    if (!this.isTyping) {
                        // update input by calculated expressions, patch values or data loads from store actions
                        this.valueToSend({
                            ...this.value,
                            phoneNumber: valueToRender
                        });
                    }

                    // fieldIsDisabled
                    this.templateData.fieldIsDisabled =
                        ctrl.disabledByRelation || ctrl.fieldConfig.disabled === true;

                    // fieldIsRequired
                    const hasRequiredValidator = (
                        ctrl.fieldConfig.validation as ValidatorFn[]
                    ).some(val => val === Validators.required);
                    this.templateData.fieldIsRequired =
                        ctrl.requiredByRelation || hasRequiredValidator;

                    // fieldIsDirty
                    this.templateData.fieldIsDirty = ctrl.isDirty;

                    // ctrlErrors
                    this.templateData.ctrlErrors = ctrl.validation;

                    // ctrlHasRequiredError
                    this.templateData.ctrlHasRequiredError =
                        ctrl.validation && ctrl.validation['required'];

                    // ctrlIsInvalid
                    this.templateData.ctrlIsInvalid = ctrl.validation !== null;

                    // canDisplayErrors
                    const hasNonRequiredErrors =
                        !!ctrl.validation &&
                        Object.keys(ctrl.validation).some(key => key !== 'required');
                    this.templateData.canDisplayErrors =
                        (ctrl.validation !== null && ctrl.isDirty) || hasNonRequiredErrors;

                    // Server Validation errors
                    handleServerValdationErrors(ctrl, evt =>
                        (this.elemRef.nativeElement as HTMLElement).dispatchEvent(evt)
                    );

                    this.cd.detectChanges();

                    this.isTyping = false;
                })
        );

        const countryCodeValueToRender = this.formStore
            .getDataStore<string>(getDataPathByFieldConfig(this.configCountryCode))
            .pipe(map(value => this.mask(unMaskSeparatorValue(value) as string)));

        this.subscriptions.push(
            ctrlStore$
                .pipe(auditTime(5), withLatestFrom(countryCodeValueToRender))
                .subscribe(([ctrl, valueToRender]) => {
                    if (!this.isTyping) {
                        // update input by calculated expressions, patch values or data loads from store actions
                        this.valueToSend({
                            ...this.value,
                            countryCode: valueToRender
                        });
                    }

                    this.cd.detectChanges();
                })
        );

        // Notifications
        this.subscriptions.push(
            combineLatest([
                valueToRender$.pipe(
                    first(),
                    map(rawValue =>
                        this.valueToSend({
                            ...this.value,
                            phoneNumber: rawValue
                        })
                    )
                ),
                this.userInputEmitter$
            ])
                .pipe(
                    filter(
                        ([defaultValue, userInput]) =>
                            stringIsNotEmpty(defaultValue) && defaultValue !== userInput
                    )
                )
                .subscribe(_ => {
                    const aixEvt = new CustomEvent(FieldEvent.CHANGE, {
                        detail: this.config,
                        bubbles: true,
                        cancelable: true
                    });
                    this.elemRef.nativeElement.dispatchEvent(aixEvt);
                })
        );

        // Store Actions
        this.subscriptions.push(
            this.userInputEmitter$
                .pipe(
                    debounceTime(100),
                    filter(value => /\d\d\d\d\d\d\d\d\d\d/.test(value.phoneNumber))
                )
                .subscribe(value => {
                    this.helper.dispatchStoreActions(this.store, this.config, value);
                })
        );

        // On Load Store Actions
        this.subscriptions.push(
            valueToRender$
                .pipe(
                    first(),
                    map(rawValue =>
                        this.valueToSend({
                            ...this.value,
                            phoneNumber: rawValue
                        })
                    )
                )
                .subscribe(value =>
                    this.helper.dispatchOnLoadStoreActions(this.store, this.config, value)
                )
        );

        this.cd.detectChanges();
    }

    valueChange(value: IntlPhoneValue) {
        this.isTyping = true;
        this.value = {
            countryCode: value.countryCode || '1',
            phoneNumber: value.phoneNumber || ''
        };
        this.debouncer.next(value);
    }

    setDirty() {
        this.store.dispatch(
            new DynamicFormControlSetDirtyAction(
                this.formStore.formUID,
                this.config.refId as string
            )
        );
    }

    valueToSend(rawValue: IntlPhoneValue) {
        const unmaskedValue = unMaskSeparatorValue(
            rawValue.phoneNumber ?? '',
            rawValue.countryCode === '1' ? this.MAX_LENGTH : undefined
        );
        this.value = {
            countryCode: rawValue.countryCode || '1',
            phoneNumber: unmaskedValue && unmaskedValue.length ? unmaskedValue : ''
        };

        // If there's no country code set US as default
        if (!rawValue.countryCode) {
            this.store.dispatch(
                new DynamicFormSetDataAction(
                    this.formStore.formUID,
                    getDataPathByFieldConfig({
                        ...this.config,
                        name: this.config.countryCodeName
                    }),
                    '1'
                )
            );
        }

        return this.value;
    }

    calculateClassNames() {
        let classNames = this.config.classNames
            ? [
                  AixDynamicIntlPhoneComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicIntlPhoneComponent.HOST_CLASS;
        classNames = this.config.hidden
            ? classNames.concat(` ${getHostPropertyClass().hidden}`)
            : classNames;
        return classNames;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.formStore.removeControl(this.config);
    }
}
