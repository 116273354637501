import { Component, input, output } from '@angular/core';
import { NgStyle } from '@angular/common';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-inline-dropdown',
    templateUrl: 'aix-inline-dropdown.component.html',
    styleUrls: ['aix-inline-dropdown.component.scss'],
    standalone: true,
    imports: [AixDataTestingDirective, NgStyle]
})
export class AixInlineDropdownComponent {
    dropdownOptions = input<string[]>([]);
    label = input<string>();
    disabled = input(false);
    disabledOptions = input<string[]>([]);
    styles = input<any>({
        links: {
            color: null
        }
    });

    optionSelected = output<string>();

    constructor() {}

    optionClicked(option: string) {
        this.optionSelected.emit(option);
    }
}
