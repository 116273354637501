import {
    ComponentRef,
    Directive,
    effect,
    input,
    Type,
    ViewContainerRef,
    inject
} from '@angular/core';
import { DefaultDropdownCellrendererComponent } from './default-dropdown.cellrenderer';
import { WrappedOption } from './dropdown';

export interface DropdownCellrenderer {
    option: WrappedOption;
}

@Directive({
    selector: '[aixDropdownCellrenderer]',
    standalone: true
})
export class DropdownCellrendererDirective {
    private container = inject(ViewContainerRef);

    option = input<WrappedOption>();
    cellRenderer = input<Type<DropdownCellrenderer>>();

    component: ComponentRef<DropdownCellrenderer>;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        effect(() => {
            const cellRenderer = this.cellRenderer() || DefaultDropdownCellrendererComponent;
            this.component = this.container.createComponent(cellRenderer);
            this.component.instance.option = <WrappedOption>this.option();
        });
    }
}
