import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    inject
} from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { getHostPropertyClass } from '../../dynamic-form.constants';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { CheckboxFieldConfig } from '@trade-platform/form-fields';
import { Field, FieldEvent } from '../field.interface';
import { DynamicFormState } from '../../dynamic-form-store/model';
import { DynamicFormStore } from '../../dynamic-form-store';
import { getDataPathByFieldConfig } from '../../dynamic-form-store/utils';
import { filter, first, withLatestFrom } from 'rxjs/operators';
import {
    DynamicFormControlSetDirtyAction,
    DynamicFormSetDataAction
} from '../../dynamic-form-store/actions';
import { objectHasValue } from '@trade-platform/ui-utils';
import { handleServerValdationErrors } from '../../dynamic-form.utils';
import { AixDynamicNextPendingFieldDirective } from '../../directives/dynamic-next-pending-field';
import { AixCheckboxComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-dynamic-checkbox',
    styleUrls: ['./checkbox.component.scss'],
    templateUrl: './checkbox.component.html',
    standalone: true,
    imports: [AixCheckboxComponent, AixDynamicNextPendingFieldDirective]
})
export class AixDynamicCheckboxComponent implements Field, OnInit, OnDestroy {
    private helper = inject(DynamicFormHelper);
    private store = inject<Store<Record<string, DynamicFormState>>>(Store);
    private formStore = inject(DynamicFormStore);
    private elemRef = inject(ElementRef);
    private cd = inject(ChangeDetectorRef);

    // Static
    static HOST_CLASS = '';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicCheckboxComponent.HOST_CLASS;

    @HostBinding('attr.aix-control')
    aixControl: string;

    // Store Data
    templateData = {
        valueToRender: false,
        fieldIsDisabled: false,
        fieldIsRequired: false,
        fieldIsDirty: false,
        ctrlHasRequiredError: false,
        ctrlIsInvalid: false
    };
    userInputEmitter$ = new Subject<boolean>();

    // Other
    config: CheckboxFieldConfig;
    private subscriptions: Subscription[] = [];
    bodyClassNames: string[];
    headerClassNames: string[];

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.cd.detach();
    }

    ngOnInit() {
        const formUID = this.formStore.formUID;
        const refId = this.config.refId as string;
        this.aixControl = refId;
        this.calculateClassNames();

        // Control initialization
        this.formStore.addControl(this.config);

        const ctrlStore$ = this.formStore.getControlStoreByRefId(refId);
        const valueToRender$ = this.formStore.getDataStore<boolean>(
            getDataPathByFieldConfig(this.config)
        );

        this.subscriptions.push(
            ctrlStore$.pipe(withLatestFrom(valueToRender$)).subscribe(([ctrl, valueToRender]) => {
                // valueToRender
                this.templateData.valueToRender = valueToRender;

                // fieldIsDisabled
                this.templateData.fieldIsDisabled =
                    ctrl.disabledByRelation || ctrl.fieldConfig.disabled === true;

                // fieldIsRequired
                const hasRequiredValidator = (ctrl.fieldConfig.validation as ValidatorFn[]).some(
                    val => val === Validators.required || val === Validators.requiredTrue
                );
                this.templateData.fieldIsRequired = ctrl.requiredByRelation || hasRequiredValidator;

                // fieldIsDirty
                this.templateData.fieldIsDirty = ctrl.isDirty;

                // ctrlHasRequiredError
                this.templateData.ctrlHasRequiredError =
                    ctrl.validation && ctrl.validation['required'];

                // ctrlIsInvalid
                this.templateData.ctrlIsInvalid = ctrl.validation !== null;

                // Server Validation errors
                handleServerValdationErrors(ctrl, evt =>
                    (this.elemRef.nativeElement as HTMLElement).dispatchEvent(evt)
                );

                this.cd.detectChanges();
            })
        );

        // User Input
        this.subscriptions.push(
            this.userInputEmitter$.subscribe(value => {
                this.store.dispatch(
                    new DynamicFormSetDataAction(
                        formUID,
                        getDataPathByFieldConfig(this.config),
                        value
                    )
                );
                this.store.dispatch(new DynamicFormControlSetDirtyAction(formUID, refId));
                this.helper.dispatchStoreActions(this.store, this.config, value);
            })
        );

        // Notifications
        this.subscriptions.push(
            combineLatest([valueToRender$.pipe(first()), this.userInputEmitter$])
                .pipe(
                    filter(
                        ([defaultValue, userInput]) =>
                            objectHasValue(defaultValue) && defaultValue !== userInput
                    )
                )
                .subscribe(_ => {
                    const aixEvt = new CustomEvent(FieldEvent.CHANGE, {
                        detail: this.config,
                        bubbles: true,
                        cancelable: true
                    });
                    this.elemRef.nativeElement.dispatchEvent(aixEvt);
                })
        );

        // On Load Store Actions
        this.subscriptions.push(
            valueToRender$
                .pipe(first())
                .subscribe(value =>
                    this.helper.dispatchOnLoadStoreActions(this.store, this.config, value)
                )
        );
        this.cd.detectChanges();
    }

    setDirty() {
        this.store.dispatch(
            new DynamicFormControlSetDirtyAction(
                this.formStore.formUID,
                this.config.refId as string
            )
        );
    }

    calculateClassNames() {
        this.classNames = this.config.classNames
            ? [
                  AixDynamicCheckboxComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicCheckboxComponent.HOST_CLASS;
        this.classNames = this.config.hidden
            ? this.classNames.concat(` ${getHostPropertyClass().hidden}`)
            : this.classNames;

        if (this.config && this.config.classNames && this.config.classNames.body) {
            this.bodyClassNames = this.helper.parseHeaderProperties(this.config.classNames.body);
        }

        if (this.config.classNames && this.config.classNames.header) {
            this.headerClassNames = this.helper.parseHeaderProperties(
                this.config.classNames.header
            );
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.formStore.removeControl(this.config);
    }
}
