import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    inject
} from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { v4 as uuid } from 'uuid';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { getHostPropertyClass } from '../../dynamic-form.constants';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { ToggleFieldConfig } from '@trade-platform/form-fields';
import { Field, FieldEvent } from '../field.interface';
import { DynamicFormState } from '../../dynamic-form-store/model';
import { DynamicFormStore } from '../../dynamic-form-store';
import { getDataPathByFieldConfig } from '../../dynamic-form-store/utils';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';
import {
    DynamicFormControlSetDirtyAction,
    DynamicFormSetDataAction
} from '../../dynamic-form-store/actions';
import { ENVIRONMENT, IEnvironment, objectHasValue } from '@trade-platform/ui-utils';
import { handleServerValdationErrors } from '../../dynamic-form.utils';
import { AixDynamicNextPendingFieldDirective } from '../../directives/dynamic-next-pending-field';
import { NgClass } from '@angular/common';
import { AixDataTestingDirective } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-dynamic-toggle',
    styleUrls: ['./toggle.component.scss'],
    templateUrl: './toggle.component.html',
    standalone: true,
    imports: [AixDataTestingDirective, NgClass, AixDynamicNextPendingFieldDirective]
})
export class AixDynamicToggleComponent implements Field, OnInit, OnDestroy {
    private helper = inject(DynamicFormHelper);
    private store = inject<Store<Record<string, DynamicFormState>>>(Store);
    private formStore = inject(DynamicFormStore);
    private elemRef = inject(ElementRef);
    private cd = inject(ChangeDetectorRef);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    // Static
    static HOST_CLASS = 'aix-flex-grid aix-flex-grid__col';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicToggleComponent.HOST_CLASS;

    @HostBinding('attr.data-testing')
    dataTesting: string;

    @HostBinding('attr.aix-control')
    aixControl: string;

    // Store Data
    templateData = {
        valueToRender: false,
        fieldIsDisabled: false,
        fieldIsRequired: false,
        ctrlIsInvalid: false,
        ctrlHasRequiredError: false,
        canDisplayErrors: false,
        ctrlErrors: null as ValidationErrors | null
    };
    userInputEmitter$ = new Subject<Event>();

    // Other
    config: ToggleFieldConfig;
    private subscriptions: Subscription[] = [];
    controlName: string;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.cd.detach();
    }

    ngOnInit() {
        this.classNames = this.calculateClassNames();

        // (joanllenas) This fixes a bug when this control is inside a Repeater.
        // Make sure the name is unique to avoid collisions with other toggle instances with the same name.
        this.controlName = this.config.name + uuid();

        const formUID = this.formStore.formUID;
        const refId = this.config.refId as string;

        // Control initialization
        this.aixControl = refId;
        this.formStore.addControl(this.config);

        // Currently binding a directive to the host is not possible in Angular (but on their roadmap - https://angular.io/guide/roadmap#support-adding-directives-to-host-elements);
        // Conditionally set this value based on the active environment here instead;
        if (this.environment.environment !== 'production') {
            this.dataTesting = refId;
        }

        // Store Control Observables
        const ctrlStore$ = this.formStore.getControlStoreByRefId(refId);
        const valueToRender$ = this.formStore.getDataStore<boolean>(
            getDataPathByFieldConfig(this.config)
        );

        this.subscriptions.push(
            ctrlStore$.pipe(withLatestFrom(valueToRender$)).subscribe(([ctrl, valueToRender]) => {
                // valueToRender
                this.templateData.valueToRender = valueToRender;

                // fieldIsDisabled
                this.templateData.fieldIsDisabled =
                    ctrl.disabledByRelation || ctrl.fieldConfig.disabled === true;

                // fieldIsRequired
                const hasRequiredValidator = (ctrl.fieldConfig.validation as ValidatorFn[]).some(
                    val => val === Validators.required
                );
                this.templateData.fieldIsRequired = ctrl.requiredByRelation || hasRequiredValidator;

                // ctrlErrors
                this.templateData.ctrlErrors = ctrl.validation;

                // ctrlHasRequiredError
                this.templateData.ctrlHasRequiredError =
                    ctrl.validation && ctrl.validation['required'];

                // ctrlIsInvalid
                this.templateData.ctrlIsInvalid = !this.templateData.valueToRender;

                // canDisplayErrors
                this.templateData.canDisplayErrors =
                    ctrl.validation !== null &&
                    Object.keys(ctrl.validation).some(key => key !== 'required');

                // Server Validation errors
                handleServerValdationErrors(ctrl, evt =>
                    (this.elemRef.nativeElement as HTMLElement).dispatchEvent(evt)
                );

                this.cd.detectChanges();
            })
        );

        // User Input
        const userInput$ = this.userInputEmitter$.pipe(
            map(evt => (evt.target as HTMLInputElement).checked)
        );

        this.subscriptions.push(
            userInput$.subscribe(value => {
                this.store.dispatch(
                    new DynamicFormSetDataAction(
                        formUID,
                        getDataPathByFieldConfig(this.config),
                        value
                    )
                );
                this.store.dispatch(new DynamicFormControlSetDirtyAction(formUID, refId));

                // Store Actions
                this.helper.dispatchStoreActions(this.store, this.config, value);
            })
        );

        // Notifications
        this.subscriptions.push(
            combineLatest([valueToRender$.pipe(first()), userInput$])
                .pipe(
                    filter(
                        ([defaultValue, userInput]) =>
                            objectHasValue(defaultValue) && defaultValue !== userInput
                    )
                )
                .subscribe(_ => {
                    const aixEvt = new CustomEvent(FieldEvent.CHANGE, {
                        detail: this.config,
                        bubbles: true,
                        cancelable: true
                    });
                    this.elemRef.nativeElement.dispatchEvent(aixEvt);
                })
        );

        // On Load Store Actions
        this.subscriptions.push(
            valueToRender$
                .pipe(first())
                .subscribe(value =>
                    this.helper.dispatchOnLoadStoreActions(this.store, this.config, value)
                )
        );

        this.cd.detectChanges();
    }

    onToggle(event: any) {
        this.userInputEmitter$.next(event);
    }

    private calculateClassNames() {
        let classNames = this.config.classNames
            ? [
                  AixDynamicToggleComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicToggleComponent.HOST_CLASS;
        classNames = this.config.hidden
            ? classNames.concat(` ${getHostPropertyClass().hidden}`)
            : classNames;

        return classNames;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.formStore.removeControl(this.config);
    }
}
